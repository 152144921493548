import { TFunction } from 'i18next';
import { ColumnsType } from 'antd/lib/table/InternalTable';

import { formatNumber } from '@/utils/formatNumber';
import withLoadingSkeleton from '@/HOCs/withLoadingSkeleton';
import { ShareDetail } from '../types';
import dayjs from 'dayjs';
import { Tag } from 'antd';

export const sharesDetailsColumns = (
  isLoading: boolean,
  t: TFunction<'captable', 'overview'>,
): ColumnsType<ShareDetail | Record<string, number>> => [
  {
    title: t('prefix'),
    render: withLoadingSkeleton(isLoading, (val: { prefix: string; equity_class_color: string }) => (
      <Tag color={val.equity_class_color}>{val.prefix}</Tag>
    )),
  },
  {
    title: t('equityType'),
    dataIndex: 'equity_type',
    render: withLoadingSkeleton(isLoading, (equity_type: string) => {
      return <span style={{ textTransform: 'capitalize' }}>{t(equity_type.toLowerCase())}</span>;
    }),
  },
  {
    title: t('className'),
    dataIndex: 'class_name',
    render: withLoadingSkeleton(isLoading, (equity_class: string) => {
      return equity_class;
    }),
  },

  {
    title: t('pricePerShare'),
    dataIndex: 'price_per_share',
    render: withLoadingSkeleton(isLoading, (val: number) => formatNumber(val, { maximumFractionDigits: 5 })),
  },
  {
    title: t('issuanceDate'),
    dataIndex: 'issuance_date',
    render: withLoadingSkeleton(isLoading, (val: number) => dayjs(val).format('DD MMM YYYY')),
  },
  {
    title: t('numberOfShares'),
    dataIndex: 'shares_authorized',
    render: withLoadingSkeleton(isLoading, (val: number) => formatNumber(val)),
  },
];

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Grid, Typography, RadioChangeEvent } from 'antd';

import { PageWrapper } from '@/components/Shared/Wrapper';
import CommonTable from '@/components/Shared/CommonTable';
import themeToken from '@lib/theme/tokens/index';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import EmptyWalletIcon from '@/components/Icons/EmptyWalletIcon';
import { ScenarioModelingSpace } from '@/features/scenarioModeling/components/Shared/Space';
import { StyledDivider } from '@/components/Shared/Divider';
import FinancialMetricDisplay from '@/features/scenarioModeling/components/Shared/FinancialMetricDisplay';
import { formatNumber } from '@/utils/formatNumber';
import PieChartIcon from '@/components/Icons/PieChartIcon';
import { StyledRadio } from '@/lib/theme/components/Radio';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import MainContainer from '@/components/Shared/MainContainer';

import DashboardCard from '../components/DashboardCard';
import { convertibleInstrumentsColumns } from '../utils/convertibleInstrumentsColumns';
import { useGetInvestmentOverview } from '../api/getInvestmentOverview';
import { optionsColumns } from '../utils/optionsColumn';
import { sharesDetailsColumns } from '../utils/sharesDetailsColumns';
import InvestmentOverviewDetails from '../components/InvestmentOverview/InvestmentOverviewDetails';
import VestingGraph from '../components/InvestmentOverview/VestingGraph';
import type { Issue, Option } from '../types';
import dayjs from 'dayjs';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const InvestmentOverview = () => {
  const { selectedCompany, fullCompanyData } = useSelectedCompanyStore((state) => state);
  const screens = useBreakpoint();
  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });

  const { t: translation } = useTranslation('pageTitles');

  const { data: investmentOverviewData, isLoading } = useGetInvestmentOverview({
    companyID: selectedCompany?.cid as number,
  });

  const [grant, setGrant] = useState(investmentOverviewData?.options?.[0]?.grant_name ?? '');
  const [optionAndEquityShares, setOptionAndEquityShares] = useState<Option[]>([]);

  const sharesDetailsTableColumns = sharesDetailsColumns(isLoading, t);
  const convertibleTableColumns = convertibleInstrumentsColumns(
    isLoading,
    fullCompanyData?.company_data.company_currency.code as string,
    t,
  );
  const optionsTableColumns = optionsColumns(isLoading, t);

  const handleGrantChange = (e: RadioChangeEvent) => {
    setGrant((e.target as HTMLInputElement).value);
  };
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Option>();

  useEffect(() => {
    if (investmentOverviewData?.options) {
      setGrant(investmentOverviewData?.options?.[0]?.grant_name || '');
      setOptionAndEquityShares([
        ...investmentOverviewData.options.map((item: Option) => {
          return { ...item, type: t('option') };
        }),
        ...investmentOverviewData.issues
          .map((item: Issue) => {
            return { ...item, type: t('equity') };
          })
          .filter((item: Issue) => item.vesting_schedule),
      ]);
    }
  }, [investmentOverviewData, t]);

  useDocumentTitle(translation('investmentOverview'));

  return (
    <MainContainer
      title={t('investmentOverview')}
      breadcrumbItems={[
        {
          title: (
            <>
              <EmptyWalletIcon />
              {t('investmentOverview')}
            </>
          ),
        },
      ]}
    >
      <Flex gap={16} vertical={!screens.xl}>
        <Flex gap={16} vertical>
          <DashboardCard>
            <Flex gap={12} vertical>
              <Flex align="center" gap={5}>
                <PieChartIcon width={18} height={18} />
                <Title
                  style={{
                    color: themeToken.colorPrimary,
                    marginInlineStart: '5px',
                    fontSize: '14px',
                  }}
                >
                  {t('myEquity')}
                </Title>
              </Flex>
              <ScenarioModelingSpace
                direction={screens.md ? 'horizontal' : 'vertical'}
                size={screens.md ? 12 : 24}
                split={
                  screens.md && (
                    <StyledDivider
                      type="vertical"
                      style={{
                        height: '46px',
                        margin: 0,
                      }}
                    />
                  )
                }
                align="center"
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  background: 'none',
                }}
              >
                <FinancialMetricDisplay
                  title={t('shares')}
                  value={`${formatNumber(investmentOverviewData?.total_shares as number)} ${t('shares')}`}
                />
                <FinancialMetricDisplay
                  title={t('sharesValue')}
                  value={`${formatNumber(investmentOverviewData?.shares_value as number, {
                    maximumFractionDigits: 5,
                  })}`}
                />
                <FinancialMetricDisplay
                  title={t('myInvestments')}
                  value={`${formatNumber(investmentOverviewData?.total_investment as number)}`}
                />
                <FinancialMetricDisplay
                  title={t('ownershipFDPercentage')}
                  value={`${formatNumber(investmentOverviewData?.ownership ?? 0, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} %`}
                />
              </ScenarioModelingSpace>
            </Flex>
          </DashboardCard>
          <Flex gap={16} vertical={!screens.lg}>
            <DashboardCard>
              <Flex gap={8} vertical>
                <Flex vertical>
                  <Title level={5} style={{ fontWeight: '400', color: '#A7B1C9' }}>
                    {t('multiples')}
                  </Title>
                </Flex>
                <Title level={4} style={{ color: themeToken.colorLink }}>
                  {formatNumber(investmentOverviewData?.multiple ?? 0, { maximumFractionDigits: 2 })}
                </Title>
              </Flex>
            </DashboardCard>
            <DashboardCard>
              <Flex gap={8} vertical>
                <Flex vertical>
                  <Title level={5} style={{ fontWeight: '400', color: '#A7B1C9' }}>
                    {t('currentPricePerShare')}
                  </Title>
                </Flex>
                <Title level={4} style={{ color: themeToken.colorLink }}>
                  {formatNumber(investmentOverviewData?.current_price_per_share ?? 0, { maximumFractionDigits: 5 })}
                </Title>
              </Flex>
            </DashboardCard>
          </Flex>

          <PageWrapper
            gap={36}
            style={{
              minHeight: 'unset',
            }}
          >
            <Flex vertical>
              <Flex
                style={{
                  marginBottom: themeToken.marginMD,
                  height: 'auto',
                }}
                vertical={!screens.sm}
              >
                <Title
                  level={5}
                  style={{
                    color: themeToken['branding-primary-6'],
                    fontWeight: 600,
                    marginBottom: themeToken.marginXXS,
                  }}
                >
                  {t('mySharesDetails')}
                </Title>
              </Flex>
              <CommonTable
                columns={sharesDetailsTableColumns}
                dataSource={investmentOverviewData?.shares_details}
                isLoading={isLoading}
                pagination={false}
                summaryTitle={t('total')}
                summaryTitleOffset={5}
                summaryValues={[
                  formatNumber(
                    investmentOverviewData?.shares_details.reduce((acc, curr) => acc + curr.shares_authorized, 0) ?? '',
                  ),
                ]}
              />
            </Flex>
          </PageWrapper>

          <PageWrapper
            gap={36}
            style={{
              minHeight: 'unset',
            }}
          >
            <Flex vertical>
              <Flex
                style={{
                  marginBottom: themeToken.marginMD,
                }}
                vertical={!screens.sm}
              >
                <Title
                  level={5}
                  style={{
                    color: themeToken['branding-primary-6'],
                    fontWeight: 600,
                    marginBottom: themeToken.marginXXS,
                  }}
                >
                  {t('convertibleInstruments')}
                </Title>
              </Flex>
              <CommonTable
                columns={convertibleTableColumns}
                dataSource={investmentOverviewData?.instruments}
                isLoading={isLoading}
                pagination={false}
              />
            </Flex>
          </PageWrapper>
          <PageWrapper
            gap={36}
            style={{
              minHeight: 'unset',
            }}
          >
            <Flex vertical>
              <Flex
                style={{
                  marginBottom: themeToken.marginMD,
                  height: 'auto',
                }}
                vertical={!screens.sm}
              >
                <Title
                  level={5}
                  style={{
                    color: themeToken['branding-primary-6'],
                    fontWeight: 600,
                    marginBottom: themeToken.marginXXS,
                  }}
                >
                  {t('vestingDetails')}
                </Title>
              </Flex>
              <CommonTable
                columns={optionsTableColumns}
                dataSource={optionAndEquityShares}
                isLoading={isLoading}
                pagination={false}
                onRow={(record: Option | Record<string, number>) => {
                  return {
                    style: {
                      cursor: 'pointer',
                    },
                    onClick: () => {
                      setOpen(true);
                      setSelectedItem(record as Option);
                    },
                  };
                }}
              />

              {open && selectedItem && (
                <InvestmentOverviewDetails
                  open={open}
                  setOpen={setOpen}
                  vestingStartDate={dayjs(selectedItem?.vesting_start_date).format('MMM YY')}
                  data={selectedItem?.vesting_schedule}
                  grantName={selectedItem?.grant_name}
                />
              )}
            </Flex>
          </PageWrapper>

          {optionAndEquityShares.filter((item) => item.vesting_schedule).length > 0 ? (
            <PageWrapper
              gap={36}
              style={{
                minHeight: 'unset',
              }}
            >
              <Flex vertical>
                <Flex
                  style={{
                    marginBottom: themeToken.marginMD,
                    height: 'auto',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Title
                    level={5}
                    style={{
                      color: themeToken['branding-primary-6'],
                      fontWeight: 600,
                      marginBottom: themeToken.marginXXS,
                    }}
                  >
                    {t('vestingPlansOverTime')}
                  </Title>
                  <StyledRadio.Group
                    defaultValue={grant}
                    size="large"
                    buttonStyle="solid"
                    value={grant}
                    onChange={handleGrantChange}
                  >
                    {optionAndEquityShares
                      .filter((item) => item.vesting_schedule)
                      .map((option) => (
                        <StyledRadio.Button
                          style={{
                            backgroundColor: grant === option.grant_name ? '#D81159' : themeToken.white,
                            color: grant === option.grant_name ? themeToken.white : '#768598',
                            borderColor: grant === option.grant_name ? '#D81159' : '#768598',
                            fontSize: themeToken.fontSizeHeading7,
                            padding: '0px 24px',
                            fontWeight:
                              grant === option.grant_name ? themeToken.fontWeightXLG : themeToken.fontWeightNormal,
                          }}
                          key={option.id}
                          value={option.grant_name}
                        >
                          {option.grant_name}
                        </StyledRadio.Button>
                      ))}
                  </StyledRadio.Group>
                </Flex>
                <VestingGraph
                  graphData={optionAndEquityShares.filter((item) => item.vesting_schedule)}
                  selectedGrant={grant}
                  isLoading={isLoading}
                />
              </Flex>
            </PageWrapper>
          ) : null}
        </Flex>
      </Flex>
    </MainContainer>
  );
};

export default InvestmentOverview;

import { useTranslation } from 'react-i18next';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, ReferenceLine } from 'recharts';

import themeToken from '@lib/theme/tokens/index';

import VestingCustomTooltip from './VestingCustomTooltip';
import type { VestingScheduleData } from '../../types';
import dayjs from 'dayjs';

type Props = {
  data: VestingScheduleData[];
  currentVestingDate?: Date;
  cliff?: number;
  frequency?: number;
};

const VestingAreaChart = ({ data, currentVestingDate, frequency, cliff }: Props) => {
  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });
  const formattedData = data?.map((el) => ({ ...el, date: dayjs(el.date.toString()).format('MMM YYYY') }));
  const formmattedCurrentVestingDate = dayjs(currentVestingDate?.toString()).format('MMM YYYY');
  const _frequency = frequency || 1;
  return (
    <ResponsiveContainer width={'100%'} height={370}>
      <AreaChart data={formattedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <ReferenceLine
          x={formmattedCurrentVestingDate}
          label={{ position: 'insideTopLeft', value: t('currentVesting'), fill: 'green' }}
          stroke="green"
        />
        {cliff && (
          <ReferenceLine
            x={dayjs(data?.[cliff / _frequency]?.date).format('MMM YYYY')}
            label={{ position: 'insideTopLeft', value: t('cliff'), fill: 'orange', dy: 20 }}
            stroke="orange"
          />
        )}
        <Tooltip content={<VestingCustomTooltip />} />
        <Area
          type="stepAfter"
          dataKey="shares_percentage"
          stroke={themeToken['branding-primary-6']}
          fill="#8884d8"
          strokeWidth={'3px'}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default VestingAreaChart;

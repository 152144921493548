import { TFunction } from 'i18next';
import type { ColumnsType } from 'antd/es/table';
import withLoadingSkeleton from '@/HOCs/withLoadingSkeleton';
import { formatNumber } from '@/utils/formatNumber';

import { Option } from '../types';

export const optionsColumns = (
  isLoading: boolean,
  t: TFunction<'captable', 'overview'>,
): ColumnsType<Option | Record<string, number>> => [
  {
    title: t('name'),
    dataIndex: 'grant_name',
    render: withLoadingSkeleton(isLoading, (val: string) => val),
  },
  {
    title: t('shares'),
    dataIndex: 'granted_shares',
    render: withLoadingSkeleton(isLoading, (val: number) => formatNumber(val)),
  },
  {
    title: t('vested'),
    dataIndex: 'vested_shares',
    render: withLoadingSkeleton(isLoading, (val: number) => formatNumber(val)),
  },
  {
    title: t('remainingToVest'),
    dataIndex: 'remaining_to_vest',
    render: withLoadingSkeleton(isLoading, (val: number) => formatNumber(val)),
  },
  {
    title: t('price'),
    dataIndex: 'exercise_price',
    render: withLoadingSkeleton(isLoading, (val: number) => formatNumber(val, { maximumFractionDigits: 5 })),
  },
  {
    title: t('totalCost'),
    dataIndex: 'total_cost',
    render: withLoadingSkeleton(isLoading, (val: number) => formatNumber(val, { maximumFractionDigits: 5 })),
  },
  {
    title: t('type'),
    dataIndex: 'type',
    render: withLoadingSkeleton(isLoading, (val: string) => val),
  },
];
